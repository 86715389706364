<template>
  <div class="w-full h-20 fixed bg-background z-999">
    <nav class="container h-full mx-auto py-2 shadow-sm">
      <div class="flex items-center h-full">
        <NuxtLink to="/">
          <NuxtImg
            format="webp"
            class="md:h-14 h-12 cursor-pointer"
            :src="logo.src"
            :alt="logo.alt"
          ></NuxtImg>
        </NuxtLink>
        <div class="flex-1"></div>
        <div class="items-center gap-x-8 text-lg hidden lg:flex">
          <!-- <ClientOnly> -->
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem v-for="item in menu">
                <NuxtLink
                  v-if="item.link"
                  :to="item.link"
                  :aria-current-value="item.title"
                  :exact-active-class="
                    item.link.match(/#/) ? 'none' : undefined
                  "
                  :class="navigationMenuTriggerStyle()"
                >
                  {{ item.title }}
                </NuxtLink>
                <NavigationMenuTrigger v-else-if="item.children">
                  {{ item.title }}
                </NavigationMenuTrigger>
                <!-- <NavigationMenuTrigger>Item One</NavigationMenuTrigger>-->
                <NavigationMenuContent v-if="item.children">
                  <div class="w-[620px] px-8 py-4">
                    <div class="grid grid-cols-2 gap-x-4 gap-y-2">
                      <template
                        v-for="child in item.children"
                        :key="child.title"
                      >
                        <component
                          :is="child.link ? NuxtLink : 'div'"
                          :to="child.link"
                          class="flex items-center gap-x-2 cursor-pointer"
                        >
                          <NuxtPicture
                            v-if="child.src"
                            :src="child.src"
                            :alt="child.title"
                            class="w-6 h-6"
                            height="24"
                            width="24"
                            format="svg"
                          ></NuxtPicture>
                          <span class="text-sm">{{ child.title }}</span>
                        </component>
                      </template>
                    </div>
                    <Separator class="m-2"></Separator>
                    <Button
                      variant="ghost"
                      size="lg"
                      class="text-[#C92868] hover:text-[#C92868] flex gap-4 mx-auto"
                      @click="openModal"
                    >
                      Book Free Consultation
                      <Icon icon="mdi-plus-circle-outline"></Icon>
                    </Button>
                  </div>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <!-- <template #fallback>
              <ul class="flex">
                <li v-for="i in menu" :key="i.title">
                  <NuxtLink
                    v-if="i.link"
                    :to="i.link"
                    class="text-foreground-light"
                    >{{ i.title }}</NuxtLink
                  >
                  <span v-else>{{ i.title }}</span>
                </li>
              </ul>
            </template>
          </ClientOnly> -->
          <!-- phone number -->
          <a
            class="hidden xl:flex items-center gap-x-2"
            :href="`tel:${contact.phone}`"
            title="Call us"
            target="_blank"
          >
            <Icon icon="mdi:phone-outline" class="text-primary"></Icon>
            <span class="text-foreground-light text-sm">{{
              contact.phone
            }}</span>
          </a>
          <a
            class="hidden xl:flex items-center gap-x-2"
            :href="`mailto:${contact.email}`"
            title="Email us"
            target="_blank"
          >
            <Icon icon="mdi:email-outline" class="text-primary"></Icon>
            <span class="text-foreground-light text-sm">
              {{ contact.email }}
            </span>
          </a>
          <div class="xl:hidden block w-[20%]"></div>

          <Button
            aria-label="Get a proposal"
            class="bg-gradient-to-r from-primary to-primary-dark flex gap-x-2 h-8"
            @click="openModal"
          >
            GET A PROPOSAL
            <Icon icon="mdi:arrow-right"></Icon>
          </Button>
        </div>
        <Button
          class="primary-gradient p-2 lg:hidden flex duration-500 transform"
          aria-label="Hamburger Menu"
          @click="toggle"
        >
          <Icon
            :icon="!isOpen ? 'mdi-hamburger-menu' : 'mdi-close'"
            class="text-3xl"
          ></Icon>
        </Button>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { useHamburgerMenu } from "~/composable/useHamburgerMenu";
import { contactModal, logo, menu, contact } from "~/lib/constants";
import { navigationMenuTriggerStyle } from "../ui/navigation-menu";
import Separator from "../ui/separator/Separator.vue";

const { isOpen, toggle } = useHamburgerMenu();

const NuxtLink = resolveComponent("NuxtLink");

import ContactModal from "../global/ContactModal.vue";
import { useModal } from "vue-final-modal";
const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
    attrs: {
      ...contactModal,
    },
  });

  await modal.open();
};
</script>

<style scoped></style>
